.App {
  font-family: "Lato",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  align-items: center;
  text-align: center;
  background-color: whitesmoke;
  min-height: 100vh;
}


.App-header {
  
  display: flex;
  flex-direction: row;
  align-items: center;

  .App-logo {
    width: 20%;
  }

}

.form-header {
  margin-top: -5%;
}

.form-container {
  border: 1rem black;
  border-radius: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.form-horz-container {
  display: flex;
  flex-direction: row;
  margin-bottom: -1rem;
}

.form-class-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
}

.class-name-container {
  width: 9rem;
}

.grade-container {
  width: 5.5rem;
}

.credits-container {
  width: 9rem;
}

.footer-container {
  position: absolute;
  bottom: 2%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
}

.sidebar-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  height: 70vh;
  right: 3vw;
  top: 15vh;
  z-index: 1;
}